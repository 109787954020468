import { render, staticRenderFns } from "./AppointmentDetailsClaims.vue?vue&type=template&id=00d512fc&scoped=true"
import script from "./AppointmentDetailsClaims.vue?vue&type=script&lang=js"
export * from "./AppointmentDetailsClaims.vue?vue&type=script&lang=js"
import style0 from "./AppointmentDetailsClaims.vue?vue&type=style&index=0&id=00d512fc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00d512fc",
  null
  
)

export default component.exports